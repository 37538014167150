import React from "react"
import Blob from "./../ServiceLanding/Hero/Blob"

// OUR WORK HERO

function Hero({ props }) {
    const { title, description, sub } = props

    return (
        <section className="relative isolate grid h-[calc(100vh-75px)] items-center overflow-x-clip lg:h-[calc(100vh-104px)]">
            <div className="absolute top-1/2 left-1/2 z-[-1] w-full -translate-x-1/2 -translate-y-1/2 lg:left-0 lg:top-[-20%] lg:max-w-7xl lg:translate-y-0 lg:-translate-x-1/3">
                <Blob />
            </div>

            <div className="container mx-auto">
                <div className="border-box mx-auto grid grid-rows-4">
                    <div className="row-span-3 grid grid-cols-1 place-items-center md:grid-cols-12">
                        <div className="place mt-[95px] max-w-[600px] text-center md:col-span-5 md:col-start-1 md:mt-[0] md:w-full md:text-left">
                            <h1 className="page-title mb-4 text-6xl font-bold">
                                {title}
                                <br />
                            </h1>
                        </div>
                        <div className="z-[2] mt-[49px] md:col-span-4 md:col-start-8 md:mt-[0]">
                            <p className="mx-auto w-5/6 text-center text-sm tracking-[-0.025rem] text-[#1D2333]/[0.8] sm:text-left md:w-full md:text-lg md:leading-9">
                                {description && (
                                    <p className="hero-text mb-0 text-[#1D2333]">
                                        {description}
                                    </p>
                                )}
                                {sub && (
                                    <p className="hero-text hero-text--sub font-bold text-[#1D2333]">
                                        {sub}
                                    </p>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero

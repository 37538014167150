import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/all"

if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}

const AnimatedCaseStudy = ({ children, className, isLeft }) => {
    const blockRef = useRef()
    const overlay = useRef()
    useEffect(() => {
        const ctx = gsap.context(() => {
            const delay = isLeft ? 0 : 0.3
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: blockRef.current,
                    start: `top-=${isLeft ? 0 : 110} center`,
                    end: `top-=${isLeft ? 0 : 110} center`,
                    once: true,
                    // markers: true
                },
                defaults: { ease: "power3.out", duration: 3 }
            })
            tl.to(overlay.current, {
                scaleY: 0,
                delay: delay
            })

            tl.fromTo(
                ".img-top",
                {
                    scale: 1.2
                },
                {
                    scale: 1,
                    delay: delay
                },
                "<"
            )

            tl.fromTo(
                [".line-top", ".title-bottom"],
                {
                    y: 20,
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: delay
                },
                1
            )
        }, blockRef)
        return () => ctx.revert()
    }, [])
    return (
        <div className={"relative  " + className} ref={blockRef}>
            <div
                ref={overlay}
                class="overlay  absolute top-0 left-0 z-10 h-2/3 w-[110%] origin-top-right bg-white "
            ></div>
            {children}
        </div>
    )
}

export default AnimatedCaseStudy
